import React from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import QueryString from 'query-string';
import { navigate } from 'gatsby';
import Api from '../helpers/Api';
import Spinner from '../components/Spinner';
import {
  prepareRedirect
} from '../views/OTPView/utils';
import { loginBannerErrorState } from '../views/LoginView/utils';
import { flowConfigState } from '..';
import SETTINGS from '../constants/appSettings';
import { CODE, ERROR } from '../constants/appCopy.json';
import { pushAnalyticsEvent } from '../helpers/utils';

export default function SingPass() {
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  const flowConfig = useRecoilValue(flowConfigState);

  async function handleSingpass(code, state, flowId) {
    try {
      const targetUrl = sessionStorage.getItem('targetUrl');
      let payload = {
        code,
        state,
        flowId
      }
      if(targetUrl) { payload = { ...payload, targetUrl}};
      const verifyResponse = await Api.verifySingpass(payload);
      if(verifyResponse?.body) {
        Api._setToken(verifyResponse?.body?.accessToken); // 2FA
        const ssoResponse = await Api.getSSO({ "authCode": verifyResponse?.body?.authCode });
        const url = SETTINGS.GC_SM_TARGET;
        const userid = verifyResponse?.body?.id;

        if(ssoResponse?.body) {
          pushAnalyticsEvent(
            'uiam_login_singpass',
            'singpass_login_suceess',
            `${flowConfig?.portal} - Login with Singpass success`,
          );
          if(flowConfig?.type === 'token') {
            const callbackUrl = flowConfig.portal==='UIAM'?`${ssoResponse?.body?.callbackUrl}&targetUrl=${SETTINGS.LANDING_PAGE}`:ssoResponse?.body?.callbackUrl;
            window.location.href = callbackUrl;  
          }
        } else {
          setLoginBannerError(ERROR.SOMETHING_WRONG);
          navigate(`/login?flowId=${flowId}`);
        }
      } else {
        pushAnalyticsEvent(
          'uiam_login_singpass',
          'singpass_login_failure',
          `${flowConfig?.portal} - Login with Singpass failed`,
        );
        if(verifyResponse?.error?.data?.code === 'TARGET_URL_NOT_VALID') {
          window.location.href = verifyResponse?.error?.data?.callbackUrl;
          return;
        }
        if(verifyResponse?.error?.data?.code === CODE.USER_ACCOUNT_LOCKED || verifyResponse?.error?.data?.code === CODE.USER_ACCOUNT_TEMP_LOCKED) {
          const state = { initialRoute: 'accountresetLocked' };
          navigate(`/mfa${window.location.search}`, { state });
        } else if(verifyResponse?.error?.data?.code === CODE.USER_ACCOUNT_DELINKED_AND_LOCKED){
          const state = { initialRoute: 'accountresetLocked', modal: true };
          navigate(`/mfa${window.location.search}`, { state });
        }else {
          navigate(`/noprofile?flowId=${flowId}`);
        }
      }
    } catch (e) {
      pushAnalyticsEvent(
        'uiam_login_singpass',
        'singpass_login_failure',
        `${flowConfig?.portal} - Login with Singpass failed`,
      );
      setLoginBannerError(ERROR.SOMETHING_WRONG);
      navigate(`/login?flowId=${flowId}`);
    }
  }

  React.useEffect(() => {
    const qs = QueryString.parse(location.search);
    const code = qs.code;
    const state = qs.state;
    const flowId = state.split('--')[2];
    handleSingpass(code, state, flowId);

    // try {
    //   //     const url = new URL(data.successURL);
    //   //     if (qs.flowId) url.searchParams.set('flowId', qs.flowId);
    //   //     if (data.ssoToken) url.searchParams.set('ssoToken', data.ssoToken);
    //   //     if (qs.state) url.searchParams.set('state', qs.state);
    //   //     return url.toString();
    //   //   } catch (e) {
    //   //     return '';
    //   //   }
    // if(url) window.top.location.href  = url
  }, []);

  return <Spinner/>
}
